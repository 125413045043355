<template>
  <div>
    <van-form>
    <div class="myInvoice">
      <div>
          <van-field name="radio" label="发票类型" style="border-top-left-radius: 0.28rem;border-top-right-radius: 0.28rem">
            <template #input>
              <van-radio-group v-model="num" direction="horizontal" checked-color="#FF9966"
                               icon-size="0.39rem"  @change="InvoiceTypeChange">
                <van-radio name="1">专票</van-radio>
                &nbsp; &nbsp;
                <van-radio name="0">普票</van-radio>
              </van-radio-group>
            </template>
          </van-field>
        <van-field name="radio" label="抬头类型" input-align="right">
          <template #input>
            <van-radio-group v-model="lookUpType" direction="horizontal" checked-color="#FF9966"
                             icon-size="0.39rem"  @change="personageBtn">
              <van-radio name=0 v-show="personageShow">个人</van-radio>&nbsp; &nbsp;
              <van-radio name=1 >企业</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <div v-if="firmDataShow">
          <van-field
              v-model="myInvoiceData.lookUp"
              label="发票抬头"
              placeholder="请填写企业名称"
              input-align="right"
          />
          <van-field
              v-model="myInvoiceData.dutyNum"
              label="税号"
              placeholder="请填写纳税人识别号"
              input-align="right"
          />
          <van-field
              v-model="myInvoiceData.openingBank"
              label="开户银行"
              placeholder="请填写企业开户银行"
              input-align="right"

          />
          <van-field
              v-model="myInvoiceData.bankAccount"
              label="银行账号"
              placeholder="请填写企业银行账号"
              input-align="right"

          />
          <van-field
              v-model="myInvoiceData.companyAddress"
              label="企业地址"
              placeholder="请填写企业注册地址"
              input-align="right"

          />
          <van-field
              v-model="myInvoiceData.companyPhone"
              label="企业电话"
              placeholder="请填写企业注册电话"
              input-align="right"
              style="border-bottom-left-radius: 0.28rem;border-bottom-right-radius: 0.28rem"
          />
        </div>
        <div v-if="personageDataShow">
          <van-field
              v-model="myInvoiceData.lookUp"
              label="发票抬头"
              placeholder="请填写需要开具发票的姓名"
              input-align="right"
          />
          <van-field
              v-model="myInvoiceData.remark"
              label="备注"
              placeholder="请填写需要备注的信息"
              input-align="right"
          />
        </div>
      </div>
      <div style="border-radius: 0.28rem;overflow:hidden;">
        <div class="InformationOfTheTicketCollector">收票人信息</div>
        <van-field
            v-model="name"
            label="姓名"
            placeholder="请填写您的姓名"
            input-align="right"
            readonly
        />
        <van-field
            v-model="tel"
            label="手机号"
            placeholder="请填写您的手机号"
            input-align="right"
            readonly
        />
        <van-field
            readonly
            clickable
            name="picker"
            :value="serviceOutletsValue"
            label="地址"
            placeholder="请选择您的收票地址"
            @click="serviceOutletsShow = true"
            input-align="right"
            right-icon="arrow"
        />
        <van-action-sheet v-model="serviceOutletsShow" title="收票地址">
          <div class="content">
            <van-address-list
                v-model="chosenAddressId"
                :list="storeList"
                add-button-text="确认"
                @add="onAdd()"
                @click-item="my"
            />
          </div>
        </van-action-sheet>
      </div>
      <div @click="openTicket" class="btn">
        <div>确认</div>
      </div>
<!--      <div class="getBack">-->
<!--        <van-icon name="wap-home-o" size="15"  @click="getHome" class="getBackIcon"/>-->
<!--        <van-icon name="revoke" size="15"  @click="getBack" class="getBackIcon"/>-->
<!--      </div>-->
    </div>
    </van-form>
  </div>
</template>

<script>
import leaseApi from "../../../utils/leaseApi";
import { Toast } from 'vant';
export default {
  name: "myInvoice",
  data() {
    return {
      personageShow:true,
      firmDataShow:true,
      personageDataShow:false,
      chosenAddressId:"",
      serviceOutletsShow:false,
      serviceOutletsValue:'',
      name:"",
      tel:'',
      num:'',
      lookUpType:'',
      storeList:[ ],
      myInvoiceData: {
        type: '',//发票类型
        lookUpType: '',//抬头类型
        company: '',//企业名称
        dutyNum: '',//税号
        openingBank: '',//开户银行
        bankAccount: '',//银行账户
        companyAddress: '',//企业地址
        companyPhone: "",//企业电话
        lookUp:'',//发票抬头
        remark:'',//备注
        orderIds:[],//订单集合
        invoicedMoney:"" ,//总金额
        name:'',
        phone:'',
        siteId:'',
      },

    }
  },
  methods: {
    // 隐藏显示个人
    InvoiceTypeChange(e){
      if(e==1){
        this.personageShow=false
        this.personageDataShow=false
        this.firmDataShow=true
      }if(e==0){
        this.personageShow=true
      }
      console.log(e)
    },
    personageBtn(i){
      if(i==0){
        this.firmDataShow=false
        this.personageDataShow=true
      }if(i==1){
        this.personageDataShow=false
        this.firmDataShow=true
      }
      console.log(i)
    },
    getBack() {
      this.$router.go(-1);//返回上一层
    },
    getHome(){
      this.$router.push({path:"/lease"});//返回上一层
    },

    //开票
   async openTicket(){
      if(this.personageDataShow==true){
       if(this.myInvoiceData.lookUp==''){
         Toast('请输入发票抬头');
         return
       }else if(this.name==''){
         Toast('请输入收票人信息');
         return
       }
      }
      if(this.firmDataShow==true){
        if(this.myInvoiceData.lookUp==''){
          Toast('请输入发票抬头');
          return
        }else if(this.myInvoiceData.dutyNum==''){
          Toast('请输入税号');
          return
        }else if(this.myInvoiceData.openingBank==''){
          Toast('请输入开户银行');
          return
        }else if(this.myInvoiceData.bankAccount==''){
          Toast('请输入银行账号');
          return
        }else if(this.myInvoiceData.companyAddress==''){
          Toast('请输入企业地址');
          return
        }else if(this.myInvoiceData.companyPhone==''){
          Toast('请输入企业电话');
          return
        }else if(this.name==''){
          Toast('请输入收票人信息');
          return
        }
      }
      this.myInvoiceData.type=this.num*1
      this.myInvoiceData.lookUpType=this.lookUpType*1
      this.myInvoiceData.siteId=this.myInvoiceData.siteId*1
      const {code}=await leaseApi.openTicket(this.myInvoiceData)
      if(code==200){
        // 自定义加载图标
       await Toast.loading({
          message: '开票中..',
          forbidClick: true,
          loadingType: 'spinner',
        });
     setTimeout(function (){
       Toast.loading({
         message: '恭喜你，开票成功啦！',
         forbidClick: true,
         loadingType: 'spinner',
       });
          this.$router.push({name:'InvoiceToApplyFor'})
       }.bind(this),2000)
      }else {
        Toast.fail('开票失败');
      }

    },
  //  获取开票订单号和总金额
    getOrders(){
      this.myInvoiceData.orderIds=this.$route.params.orders
      this.myInvoiceData.invoicedMoney = this.$route.params.orderMoney
    },
    // 获取地址数据
    async getAddressData(){
      const {data}=await leaseApi.getSiteList()
      data.forEach(e=>{
        this.storeList.push({
          id:e.id,
          name:e.recipients,
          tel:e.phone,
          address:e.province+e.city+e.county+e.detailedAddress
        })
      })
    },
    onAdd() {
      this.serviceOutletsShow = false
    },
    my(item, index) {
      this.serviceOutletsValue= item.address
      this.name=item.name
      this.tel=item.tel
      this.myInvoiceData.siteId=item.id
      this.myInvoiceData.phone=item.tel
      this.myInvoiceData.name=item.name
    },
  },
  created() {
    this.getOrders()
    this.getAddressData()
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-nav-bar .van-icon {
  color: #1E243B;
}

/deep/ .van-nav-bar__text {
  color: #1E243B;
}
.myInvoice {
  height: 100%;
  background: #F6F6F6;
  padding-top: 0.28rem;
  padding-bottom: 3.5rem;
}
.InformationOfTheTicketCollector{
  font-size: 0.42rem;
  font-family: xiaocheng;
  font-weight: 500;
  color: #333333;
  background: #FFFFFF;
  margin-top: 0.28rem;
  padding: 0.26667rem 0.42667rem;
  border-top-left-radius: 0.28rem;
  border-top-right-radius: 0.28rem;
}
.btn{
  background: #FFFFFF;
  padding: 0.28rem 0rem;
  position: fixed;
  bottom: 1.6rem;
  width: 100%;
  div{
    width: 9.75rem;
    height: 1.11rem;
    background: #FB884F;
    box-shadow: 0rem 0rem 0rem 0rem rgba(251, 140, 82, 0.13);
    border-radius: 1rem;
    font-size: 0.44rem;
    font-family: xiaocheng;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 1.11rem;
    text-align: center;
    margin-left: 50%;
    transform: translateX(-50%);
  }

}
.getBack{
  position: fixed;
  right: 10px;
  top:50%;
  display: flex;
  flex-direction: column;
  .getBackIcon{
      width: 70px;
      height: 70px;
      background: #F6F6F6;
      border-radius: 50%;
      text-align: center;
      line-height: 70px;
    margin-bottom: 10px;
    }
}
/deep/.van-radio-group--horizontal{
  align-items: center;
  //justify-content: right;
}
/deep/.van-field__control--custom{
  //justify-content: left;
  padding-left: 3.5rem;
}
/deep/.van-radio__label{
  margin-left: 0.1rem;
}
/deep/.van-field__label{
  color: #333;
  font-size: 0.4rem;
}
/deep/.van-field__body{
  justify-content: right;
}
/deep/.van-field__control{
  //text-align: right;
}
/deep/.van-cell::after{
  border: 0rem;
}
/deep/.index .content{
  padding-bottom: 1rem;
  background: #f6f6f6;
}
/deep/.van-radio--horizontal{
  margin-right: 0rem;
}
/deep/.van-address-item .van-radio__icon--checked .van-icon{
  background-color:#FB884F;
  border-color:#FB884F
}
/deep/.van-button--danger{
  background-color:#FB884F;
  border:0.02667rem solid #FB884F
}
/deep/.van-address-item__edit{
  display: none;
}
</style>
